import React from "react"
import { PageProps } from "gatsby"
import { graphql } from "gatsby"

import { Seo } from "../components/Seo/Seo"

import { Navbar } from "../components/Navbar/Navbar"
import { SelectLanguageModal } from "../components/SelectLanguageModal/SelectLanguageModal"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { LayoutMdx } from "../components/LayoutMdx/LayoutMdx"
import { Drawer } from "../components/Drawer/Drawer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { Footer } from "../components/Footer/Footer"

type Props = PageProps<
  {
    mdx: {
      id: string
      body: string
      frontmatter: {
        order: number
        created_at: string
        lang: string
        level: string
        seo_description: string
        seo_title: string
        title: string
        updated_at: string
        breadcrumb_title: string
      }
    }
    course: {
      frontmatter: {
        breadcrumb_title: string
      }
    }
    lessons: {
      nodes: {
        frontmatter: {
          level: string
          lang: string
          order: number
          url: string
          title: string
          description: string
        }
      }[]
    }
  },
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const CookiesPage: React.FC<Props> = (props) => {
  const { langKey } = props.pageContext

  return (
    <GlobalWrapper>
      <Seo
        title={props.data.mdx.frontmatter.seo_title}
        description={props.data.mdx.frontmatter.seo_description}
        lang={langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SelectLanguageModal languages={props.pageContext.canonicals} />

      <div className="bg-slate-50  ">
        <div className="relative overflow-hidden ">
          <Navbar langKey={props.pageContext.langKey} />
        </div>

        <div className="py-4   bg-slate-50">
          <div>
            {props.data.mdx && (
              <>
                <LayoutMdx>
                  <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
                </LayoutMdx>
              </>
            )}
          </div>
        </div>

        <Footer lang={props.pageContext.langKey} />

        <Drawer langKey={props.pageContext.langKey} />
      </div>
    </GlobalWrapper>
  )
}

export default CookiesPage

export const query = graphql`
  query CookiePageQuery($langKey: String) {
    mdx(frontmatter: { lang: { eq: $langKey }, category: { eq: "cookie" } }) {
      body
      frontmatter {
        created_at
        updated_at
        lang
        category
        title
        seo_description
        seo_title
      }
    }
  }
`
